import { Box, Button, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Difficulty,
    getDifficulty,
    saveDifficulty,
} from '../../services/difficulty'
import { getTimes, saveTimes, Times } from '../../services/times'
import { ExclusiveOptionsGroup } from './ExclusiveOptionsGroup'
import { OptionsGroup } from './OptionsGroup'
import resource from './OptionsResources'

export function Options() {
    const [saved, setSaved] = useState(false)
    const transparently = <T,>(f: (val: T) => void, value: T) => {
        console.log('change')
        f(value)
        setSaved(true)
    }

    return (
        <Box margin={2}>
            <Typography variant="h5" paragraph>
                {resource.invitation}
            </Typography>
            <Grid container spacing={2} direction="row" marginTop={2}>
                <OptionsGroup<Difficulty>
                    title={resource.difficulty}
                    initial={getDifficulty()}
                    save={(x) => transparently(saveDifficulty, x)}
                    options={[
                        {
                            caption: resource.firstGroup,
                            parameter: 'firstGroup',
                        },
                        {
                            caption: resource.secondGroup,
                            parameter: 'secondGroup',
                        },
                        {
                            caption: resource.thirdGroup,
                            parameter: 'thirdGroup',
                        },
                    ]}
                />

                <ExclusiveOptionsGroup
                    title={resource.times}
                    initial={getTimes()}
                    options={[
                        { caption: resource.present, value: Times.present },
                        { caption: resource.easy, value: Times.easy },
                        { caption: resource.veteran, value: Times.veteran },
                        { caption: resource.hardcore, value: Times.hardcore },
                    ]}
                    save={(x) => transparently(saveTimes, x)}
                />
            </Grid>
            {
                <Button
                    component={Link}
                    to="/"
                    color={saved ? 'primary' : 'secondary'}
                    variant={saved ? 'contained' : 'outlined'}
                >
                    {saved ? resource.try : resource.back}
                </Button>
            }
        </Box>
    )
}
