import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { loggy } from './loggy'

export function watch() {
    if (process.env.REACT_APP_parly_sentry_dsn) {
        loggy('sentry is watching')
        Sentry.init({
            dsn: process.env.REACT_APP_parly_sentry_dsn,
            integrations: [new BrowserTracing()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        })
    }
}

export function handle() {
    return Sentry.withErrorBoundary
}
