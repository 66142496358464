import { groups } from '../../../services/groups'
import { Verb } from '../model/model'
import data from './reevaluer.json'

export function random(): Promise<Verb[]> {
    const resource = url()
    return resource
        ? available(`${resource}?${groups()}`).catch(fallback)
        : fallback()
}

function available(resource: string) {
    return fetch(resource)
        .then((x) => x.json())
        .then((x) => [x])
}

function fallback() {
    return dev()
        ? Promise.resolve(data as [Verb])
        : Promise.reject(
              new Error(
                  'Please configure an environment variable `REACT_APP_parly_api_random_verb_url`'
              )
          )
}

export function url(): string | undefined {
    return process.env.REACT_APP_parly_api_random_verb_url
}

function dev() {
    return process.env.NODE_ENV !== 'production'
}
