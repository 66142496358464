import { Grid, RadioGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { loggy } from '../../services/loggy'
import { RadioOption } from './RadioOption'

export function ExclusiveOptionsGroup<T>({
    title,
    initial,
    options,
    save,
}: {
    title: string
    initial: T
    save: (data: T) => void
    options: { value: T; caption: string }[]
}) {
    const [selected, setSelected] = useState(initial)
    useEffect(() => {
        if (selected !== initial) save(loggy(selected))
    }, [selected, save, initial])

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: T) => {
        if (event.target.checked && selected !== value) setSelected(value)
    }

    return (
        <>
            <Typography variant="h6" gutterBottom paragraph>
                {title}
            </Typography>
            <Grid container spacing={3} marginBottom={2}>
                <Grid item xs={9}>
                    <RadioGroup>
                        {options.map(({ value, caption }) => (
                            <RadioOption
                                key={caption}
                                value={value}
                                label={caption}
                                checked={selected}
                                callback={onChange}
                            />
                        ))}
                    </RadioGroup>
                </Grid>
            </Grid>
        </>
    )
}
