import { Box, Container } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PersistentDrawerLeft from './components/Drawer'
import { composition } from './composition'
import { useMemo } from 'react'
import { BehaviorSubject } from 'rxjs'
import { watch, handle } from './services/telemetry'
import { StreamingTitle } from './composition/StreamingTitle'

function App() {
    watch()
    const subject = useMemo(() => new BehaviorSubject<string>(''), [])
    const menu = useMemo(() => composition(subject), [subject])
    return (
        <BrowserRouter>
            <PersistentDrawerLeft
                titleStream={subject}
                navigation={menu.filter((x) => x.menu)}
            >
                <Container>
                    <Box my={4}>
                        <Routes>
                            {menu.map(({ url, element, title }) => (
                                <Route
                                    key={url}
                                    path={url}
                                    element={
                                        <StreamingTitle
                                            subject={subject}
                                            title={title}
                                        >
                                            {element}
                                        </StreamingTitle>
                                    }
                                />
                            ))}
                        </Routes>
                    </Box>
                </Container>
            </PersistentDrawerLeft>
        </BrowserRouter>
    )
}

export default handle()(App, { fallback: <>An error has occurred</> })
