import { useEffect } from 'react'

export function StreamingTitle({
    children,
    subject,
    title,
}: {
    title: string
    children: JSX.Element
    subject: { next: (value: string) => void }
}): JSX.Element {
    useEffect(() => subject.next(title))
    return <>{children}</>
}
