export function Titled({
    title,
    children,
}: {
    title: string
    children: JSX.Element
}) {
    document.title = title
    return <>{children}</>
}
