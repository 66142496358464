import { Grid, LinearProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import resources from '../../resources/resources.en'
import { mapVerb } from '../../services/mapper'
import { select } from '../../services/select'
import { VerbViewModel } from './ConjugationViewModel'
import { random } from './dummy/dummy'
import { Presenter } from './Presenter'
import { RobotInvitation } from './RobotSays'

export function VerbForm() {
    const [viewModel, setViewModel] = useState<VerbViewModel>()
    const reset = () => {
        setViewModel(undefined)
        random()
            .then((x) => x.map((y) => mapVerb(y, select)))
            .then((x) => setViewModel(x.at(0)))
    }

    useEffect(reset, [])

    return viewModel ? (
        <Grid container direction="column" alignItems="center">
            <RobotInvitation
                title={resources.invitation}
                verb={viewModel.infinitive}
                time={viewModel.time}
            />
            <Presenter reset={reset} conjugations={viewModel.conjugations} />
        </Grid>
    ) : (
        <LinearProgress variant="indeterminate" color="secondary" />
    )
}
