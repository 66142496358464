import { Conjugation, Verb } from '../views/VerbForm/model/model'
import { Selector } from './select'

export function mapVerb(verb: Verb, selector: Selector) {
    const timeConjugation = selector(verb.timeConjugations)
    const conjugations = timeConjugation.conjugations
        .sort((a, b) => a.party - b.party)
        .map(conjugate)
    return {
        infinitive: verb.infinitive,
        conjugations,
        time: timeConjugation.time,
    }
}

const conjugate = (conjugation: Conjugation) => ({
    label: conjugation.combined,
    actual: '',
    expected: conjugation.value,
})
