import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },

        secondary: {
            main: '#88c0e5',
        },
        error: {
            main: '#800000',
        },
        background: {
            default: '#fff',
        },
    },
})
