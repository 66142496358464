import { Button } from '@mui/material'

export function Submit({
    content,
    callback,
}: {
    content: string
    callback: () => void
}) {
    const click = (_: any) => callback()
    return (
        <Button
            sx={{ width: 100 }}
            variant="contained"
            color="primary"
            onClick={click}
        >
            {content}
        </Button>
    )
}
