const resource = {
    invitation: 'Options',
    difficulty: 'Please indicate difficulty',
    groups: 'Verb groups',
    times: 'Please indicate times',
    firstGroup: 'Include 1st group',
    secondGroup: 'Include 2st group',
    thirdGroup: 'Include 3rd group',
    present: 'Present',
    easy: 'Easy',
    veteran: 'Veteran',
    hardcore: 'Hardcore',
    try: 'Try it!',
    back: 'Back',
}
export default resource
