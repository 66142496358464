import { SubjectLike } from 'rxjs'
import { ApplicationPage } from './ApplicationPage'
import { trainer, trainerOptions } from './pages'

export const composition: (
    subject: SubjectLike<string>
) => ApplicationPage[] = (subject: SubjectLike<string>) => [
    trainer(),
    trainerOptions(),
]
