import { Box, Grid, Paper, Typography } from '@mui/material'

export function RobotInvitation({
    title,
    verb,
    time,
}: {
    title: string
    verb: string
    time: string
}) {
    const typographyStyle = {
        color: '#ffffff',
        fontSize: 20,
        fontFamily: 'Roboto',
    }
    return (
        <Grid
            margin={1}
            justifyContent="center"
            container
            direction="row"
            alignItems="center"
        >
            <img alt="Robot says" src="/logo128.png" />
            <Paper
                style={{
                    background: '#f7729b',
                    minWidth: 320,
                    border: `2px solid #41346a`,
                    borderRadius: 5,
                }}
                elevation={5}
            >
                <Box p={2.5}>
                    <Grid
                        container
                        direction="column"
                        justifyItems="center"
                        alignItems="center"
                    >
                        <Typography variant="h4" style={typographyStyle}>
                            {title}:
                        </Typography>
                        <Typography variant="h4" style={typographyStyle}>
                            <b>{verb}</b>
                        </Typography>
                        <Typography variant="h4" style={typographyStyle}>
                            {time}
                        </Typography>
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    )
}
