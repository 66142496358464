import { loggy } from '../../services/loggy'

export function partialUpdate<
    T extends Record<string, V>,
    K extends keyof T,
    V
>(original: T, key: K, value: V) {
    const partial = Object.defineProperty({} as Partial<T>, key, {
        value: value,
        enumerable: true,
    })
    const result = { ...original, ...partial }
    return loggy(result)
}

export function partial<T extends Record<string, V>, K extends keyof T, V>(
    key: K,
    value: V
) {
    return loggy(
        Object.defineProperty({} as Partial<T>, key, {
            value: value,
            enumerable: true,
        })
    )
}
