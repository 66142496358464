import { Field } from '../../components/Field'
import { useStateMachine } from '../../hooks/use-state-machine'
import resources from '../../resources/resources.en'
import { ConjugationViewModel } from './ConjugationViewModel'
import { Grid } from '@mui/material'
import { OpenOptions } from './OpenOptions'
import { Submit } from './Submit'

export function Presenter({ conjugations, reset }: PresenterProps) {
    const { currentState, transition, finish } = useStateMachine()
    const validity = conjugations.map((_) => false)
    const changed = (index: number, value: boolean) => {
        validity[index] = value
    }
    const submitting = () => transition(validity)
    return (
        <>
            <Grid container columnGap={1} rowGap={1} justifyContent="center">
                {conjugations.map(
                    (conjugation: ConjugationViewModel, index: number) => (
                        <Grid
                            item
                            key={index}
                            xs={10}
                            sm={4}
                            md={4}
                            lg={3}
                            xl={3}
                        >
                            <Field
                                factory={currentState.factory}
                                viewModel={conjugation}
                                changed={(value) => changed(index, value)}
                            />
                        </Grid>
                    )
                )}
            </Grid>

            <Grid
                p={3}
                container
                direction="row"
                columnGap={1}
                rowGap={1}
                justifyContent="center"
                alignContent="center"
            >
                <Grid item>
                    <Submit
                        content={finish ? resources.next : resources.submit}
                        callback={finish ? reset : submitting}
                    />
                </Grid>
                <Grid item>
                    <OpenOptions />
                </Grid>
            </Grid>
        </>
    )
}

type PresenterProps = {
    conjugations: ConjugationViewModel[]
    reset: () => void
}
