const resource = {
    conjugationTrainer: 'Conjugation trainer',
    options: 'Options',
    invitation: 'Conjugate the verb',
    submit: 'Submit',
    retry: 'Retry',
    next: 'Next',
    incorrect: 'Please retry',
    correct: 'Correct',
    applicationName: 'Parlr',
    applicationUrl: 'https://parly.me/',
}
export default resource
