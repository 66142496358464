import { useState } from 'react'
import resources from '../resources/resources.en'
import { ConjugationViewModel } from '../views/VerbForm/ConjugationViewModel'

export const useStateMachine: StateMachineHook = () => {
    const [currentState, setCurrentState] = useState<FormState>(filling)
    const transition = (data: boolean[]) =>
        setCurrentState(currentState.transition(data))
    return { currentState, transition, finish: finish === currentState }
}

export type Predicate = (reset: () => {}) => boolean
export type StateMachineHook = () => {
    currentState: FormState
    finish: boolean
    transition: (data: boolean[]) => void
}

export type FieldStateFactory = (
    conjugation: ConjugationViewModel
) => FieldState
export type Transition = (data: boolean[]) => FormState
export type FormState = { factory: FieldStateFactory; transition: Transition }
// {
//   fieldStateFactory: FieldStateFactory;
//   transition: Transition;
// };
export type FieldState = {
    disabled(): boolean
    hint(): string
    error(): boolean
}

const filling: FormState = {
    factory: (_: ConjugationViewModel) => ({
        disabled: () => false,
        hint: () => '',
        error: () => false,
    }),
    transition: (data: boolean[]) =>
        data.every((x) => x === true) ? finish : retry,
}

const retry: FormState = {
    factory: (conjugation: ConjugationViewModel) => ({
        disabled: () => conjugation.expected === conjugation.actual,
        hint: () =>
            conjugation.expected === conjugation.actual
                ? resources.correct
                : resources.incorrect,
        error: () => conjugation.expected !== conjugation.actual,
    }),
    transition: (data: boolean[]) => finish,
}

const finish: FormState = {
    factory: (conjugation: ConjugationViewModel) => ({
        disabled: () => conjugation.expected !== conjugation.actual,
        hint: () => conjugation.expected,
        error: () => conjugation.expected !== conjugation.actual,
    }),
    transition: (data: boolean[]) => filling,
}
