import { TimeConjugation } from '../views/VerbForm/model/model'
import { getTimes, Times } from './times'

export type Selector = typeof select

export function select(
    conjugations: TimeConjugation[],
    time: Times = getTimes()
): TimeConjugation {
    const random = Math.floor(Math.random() * times.get(time)!(conjugations))
    return conjugations[random]
}

export type Time = (conjugations: TimeConjugation[]) => number

const times = new Map<Times, Time>()
times.set(Times.present, (_: TimeConjugation[]) => 1)
times.set(Times.easy, (_: TimeConjugation[]) => 3)
times.set(
    Times.veteran,
    (conjugations: TimeConjugation[]) => conjugations.length / 2
)
times.set(
    Times.hardcore,
    (conjugations: TimeConjugation[]) => conjugations.length
)
