import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { loggy } from '../../services/loggy'
import { partialUpdate } from './partialUpdate'

export type OptiongsGroupProps<T> = {
    initial: T
    title: string
    save: (o: T) => void
    options: { caption: string; parameter: keyof T }[]
}

export function OptionsGroup<T extends Record<string, boolean>>({
    initial,
    save,
    title,
    options,
}: OptiongsGroupProps<T>): JSX.Element {
    const [selected, setSelected] = useState(initial)
    useEffect(() => {
        if (
            options.some((x) => selected[x.parameter] !== initial[x.parameter])
        ) {
            loggy(selected)
            save(selected)
        }
    }, [selected, save, initial, options])

    return (
        <>
            <Typography variant="h6" gutterBottom paragraph>
                {title}
            </Typography>
            <Grid container spacing={3} marginBottom={2}>
                <Grid item xs={9}>
                    {options.map(({ caption, parameter }) => (
                        <FormControlLabel
                            key={caption}
                            label={caption}
                            control={
                                <Checkbox
                                    color="secondary"
                                    name={caption}
                                    onChange={(event) => {
                                        const next = partialUpdate(
                                            selected,
                                            parameter,
                                            event.target.checked
                                        )
                                        if (next !== selected) {
                                            setSelected(next)
                                        }
                                    }}
                                    checked={selected[parameter] === true}
                                />
                            }
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    )
}
