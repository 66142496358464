import React from 'react'
import App from './App'
import { theme } from './theme'
import { createRoot } from 'react-dom/client'
import { CssBaseline, ThemeProvider } from '@mui/material'
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
</ThemeProvider>
)
