import { FormControlLabel, Radio } from '@mui/material'

export function RadioOption<T>({
    label,
    value,
    checked,
    callback,
}: {
    label: string
    value: T
    checked: T
    callback: (e: React.ChangeEvent<HTMLInputElement>, value: T) => void
}) {
    return (
        <FormControlLabel
            label={label}
            control={
                <Radio
                    color="secondary"
                    checked={checked === value}
                    value={value}
                    onChange={(e) => callback(e, value)}
                />
            }
        />
    )
}
