import resource from '../../resources/resources.en'
import { VerbForm } from '../../views/VerbForm/VerbForm'
import AssignmentIcon from '@mui/icons-material/Assignment'
import SettingsIcon from '@mui/icons-material/Settings'
import { Options } from '../../views/Options/Options'
import { ReactNode } from 'react'

export function trainer() {
    return page(
        '/',
        resource.conjugationTrainer,
        <VerbForm />,
        <AssignmentIcon />,
        true
    )
}

export function trainerOptions() {
    return page(
        '/trainer/options',
        resource.options,
        <Options />,
        <SettingsIcon />,
        false
    )
}

function page(
    url: string,
    title: string,
    element: JSX.Element,
    icon: ReactNode,
    menu: boolean
) {
    return {
        url,
        element,
        title,
        icon,
        menu,
    }
}
