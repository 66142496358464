import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { trainerOptions } from '../../composition/pages'

export function OpenOptions() {
    const options = useMemo(() => trainerOptions(), [])
    return (
        <Button
            sx={{ width: 100 }}
            variant="outlined"
            color="secondary"
            component={Link}
            to={options.url}
        >
            {options.title}
        </Button>
    )
}
