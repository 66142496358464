import { get, set } from './storage'

export type Difficulty = {
    firstGroup: boolean
    secondGroup: boolean
    thirdGroup: boolean
}

const fallback: Difficulty = {
    firstGroup: true,
    secondGroup: false,
    thirdGroup: false,
}

export const getDifficulty: () => Difficulty = () => {
    return get('difficulty', fallback)
}

export const saveDifficulty: (difficulty: Difficulty) => void = (
    difficulty
) => {
    set('difficulty', difficulty)
}
