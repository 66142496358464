import { get, set } from './storage'

export enum Times {
    present = 'present',
    easy = 'easy',
    veteran = 'veteran',
    hardcore = 'hardcore',
}

const fallback: Times = Times.present

export const getTimes: () => Times = () => {
    return get('times', fallback)
}

export const saveTimes: (times: Times) => void = (times) => {
    set('times', times)
}
