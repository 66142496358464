import { Box, TextField } from '@mui/material'
import { useState } from 'react'
import { FieldStateFactory } from '../hooks/use-state-machine'
import { update } from '../services/update'
import { ConjugationViewModel } from '../views/VerbForm/ConjugationViewModel'
import { Elevated } from '../views/VerbForm/Elevated'

export function Field({ factory, viewModel, changed }: FieldProps) {
    const [correct, setCorrect] = useState(false)
    const [vm, setVm] = useState(viewModel)
    const { disabled, error, hint } = factory(vm)

    const changing = (e: any) => {
        setVm(update(vm, e.target.value))
        if (correct !== (vm.actual === vm.expected)) {
            setCorrect(vm.actual === vm.expected)
        }
    }

    const style = {
        '& input:hover:valid  + fieldset': {
            borderColor: '#0e89e4',
            borderWidth: 2,
        },
    }

    return (
        <Elevated>
            <Box p={1}>
                <TextField
                    color="secondary"
                    sx={style}
                    fullWidth={true}
                    disabled={disabled()}
                    autoComplete="off"
                    error={error()}
                    id={vm.label}
                    label={vm.label}
                    value={vm.actual}
                    key={vm.label}
                    onChange={changing}
                    helperText={hint()}
                    variant="outlined"
                />
            </Box>
        </Elevated>
    )
}

type FieldProps = {
    factory: FieldStateFactory
    viewModel: ConjugationViewModel
    changed: (value: boolean) => void
}
